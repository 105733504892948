<template>
  <div class="px-5">
    <div class="hidden-sm-and-down">
      <v-row>
        <v-col cols="5">
          <div class="text-h4 primary--text">รายงานการขายสินค้า</div>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            color="success"
            small
            outlined
            class="ml-5 px-5"
            @click="downloadOverallCategoryExcel()"
          >
            ดาวน์โหลดเป็นหมวดหมู่
            <v-icon right small>mdi-download</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            color="success"
            small
            outlined
            class="ml-5 px-5"
            @click="downloadOverallProductExcel()"
          >
            ดาวน์โหลดเป็นสินค้า
            <v-icon right small>mdi-download</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            color="success"
            small
            outlined
            class="ml-5 px-5"
            @click="downloadOverallExcel()"
          >
            ดาวน์โหลดแบบละเอียด
            <v-icon right small>mdi-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="loading">
        <v-progress-linear color="green" class="text-center text-h6 white--text py-5" indeterminate :height="60">กำลังโหลดข้อมูล กรุณาคอยสักครู่... </v-progress-linear>
      </div>
      <div v-if="!loading">
        <v-toolbar height="120px" dense flat rounded outlined class="my-8 pt-2">
          <v-row>
            <v-col cols="2">
              <v-select
                label="หมวดหมู่หลัก"
                :items="category"
                item-value="value"
                item-text="text"
                outlined
                rounded
                dense
                hide-details
                @change="onCategoryChange"
                v-model="filter.category_id"
              >
              </v-select>
            </v-col>

            <v-col cols="2">
              <v-select
                label="หมวดหมู่ย่อย"
                :items="sub_category"
                item-value="_id"
                item-text="name"
                outlined
                rounded
                dense
                hide-details
                v-model="filter.sub_category_id"
              >
              </v-select>
            </v-col>
            <v-col cols="2" v-if="branch.split_bill_data.name">
              <v-select
                label="บิลบริษัท"
                :items="bill_select"
                item-value="value"
                item-text="text"
                outlined
                rounded
                dense
                hide-details
                v-model="filter.bill_select"
              >
              </v-select>
            </v-col>

            <v-col cols="2">
              <v-select
                label="ช่วงเวลา"
                v-model="timeRangeType"
                :items="timeRangeTypes"
                outlined
                rounded
                dense
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="2">
              <DatePicker
                v-if="timeRangeType == 1"
                label="วันที่"
                :value="filter.start_date"
                @onChange="(val) => (filter.start_date = val)"
                hideDetails
                outlined
                rounded
                dense
              />

              <v-menu
                v-else-if="timeRangeType == 2"
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="filter.month"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="filter.start_date ? getMonth(filter.start_date) : ''"
                    label="เดือน"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    hideDetails
                    outlined
                    rounded
                    dense
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  class="hidden-sm-and-down"
                  v-model="filter.month"
                  type="month"
                  no-title
                  scrollable
                  locale="th"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(filter.month), changeMonth()"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="2">
              <DatePicker
                v-if="timeRangeType == 1"
                label="ถึงวันที่"
                :value="filter.end_date"
                @onChange="(val) => (filter.end_date = val)"
                hideDetails
                outlined
                rounded
                dense
              />
            </v-col>

            <v-col cols="7"></v-col>
            <v-col cols="3">
              <span class="float-right">
                ยอดรวมราคาทั้งหมด {{ total_sales | numeral('0,0.00') }} บาท</span
              >
            </v-col>
            <v-col cols="2" class="pb-5">
              <v-btn
                color="primary"
                small
                class="float-right"
                @click="getProductReportData()"
              >
                แสดงรายงาน <v-icon right>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-row
          class="pl-3 pr-10 ml-0 mr-0 fz-1 grey lighten-4 elevation-2"
          style="border-radius: 5px 5px 0px 0px"
        >
          <v-col cols="1"></v-col>
          <v-col class="report-headers" cols="1">วันที่</v-col>
          <v-col class="report-headers" cols="1">เลขที่เอกสาร</v-col>
          <v-col class="report-headers" cols="2">ชื่อลูกค้า</v-col>
          <v-col class="report-headers" cols="2">โปรเจ็ค</v-col>
          <v-col class="report-headers" cols="1">จำนวน</v-col>
          <v-col class="report-headers" cols="1">ราคาต่อหน่วย</v-col>
          <v-col class="report-headers" cols="1">ส่วนลด</v-col>
          <v-col class="report-headers" cols="1">ภาษีมูลค่าเพิ่ม</v-col>
          <v-col class="report-headers" cols="1">ยอดรวมสุทธิ</v-col>
        </v-row>

        <v-expansion-panels accordion class="mt-3">
          <v-expansion-panel v-for="(item, i) in list" :key="i">
            <v-expansion-panel-header
              @click="loadItem(item._id)"
              class="primary--text text-h6"
            >
              <v-row class="">
                <v-col cols="7">{{ item.name }}</v-col>
                <v-col cols="1">{{ item.count }}</v-col>
                <v-col cols="3"></v-col>
                <v-col cols="1">{{ item.net_total | numeral('0,0.00') }}</v-col>
              </v-row>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div v-for="dt in product_list" :key="dt.id">
                <v-row class="pr-5">
                  <v-col cols="1" offset="1">{{ dt.date_of_issue }}</v-col>
                  <v-col cols="1">{{ dt.number }}</v-col>
                  <v-col cols="2">{{ dt.customer_name }}</v-col>
                  <v-col cols="2">{{ dt.project_name }}</v-col>
                  <v-col cols="1">{{ dt.product_count }}</v-col>
                  <v-col cols="1">{{ dt.price | numeral('0,0.00') }}</v-col>
                  <v-col cols="1">{{ dt.discount | numeral('0,0.00') }}</v-col>
                  <v-col cols="1">{{ dt.vat_price | numeral('0,0.00') }}</v-col>
                  <v-col cols="1">{{ dt.net_price | numeral('0,0.00') }}</v-col>
                </v-row>
              </div>
              <v-row v-if="product_remain != 0" class="pr-5">
                <v-col cols="11" offset="1" class="primary--text">
                  <a @click="downloadItemExcel()">
                    <!-- เหลืออีก {{ product_remain }} รายการ -->
                    ดาวน์โหลดสินค้านี้เป็น excel
                  </a>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <div class="hidden-md-and-up">
      <v-row col="12">
        <div class="text-h6 primary--text">รายงานการขายสินค้า</div>
      </v-row>

      <v-row justify="end" class="mb-1">
        <v-btn
          color="success "
          small
          outlined
          @click="downloadOverallCategoryExcel()"
        >
          ดาวน์โหลดเป็นหมวดหมู่
          <v-icon right small>mdi-download</v-icon>
        </v-btn>
      </v-row>
      <v-row justify="end" class="mb-3">
        <v-btn color="success" small outlined @click="downloadOverallExcel()">
          ดาวน์โหลดแบบละเอียด
          <v-icon right small>mdi-download</v-icon>
        </v-btn>
      </v-row>

      <v-card outlined class="mb-5">
        <v-card-text>
          <v-row>
            <v-col cols="6" class="px-1">
              <v-select
                label="หมวดหมู่หลัก"
                :items="category"
                item-value="value"
                item-text="text"
                outlined
                rounded
                dense
                hide-details
                @change="onCategoryChange"
                v-model="filter.category_id"
              >
              </v-select
            ></v-col>
            <v-col cols="6" class="px-1">
              <v-select
                label="หมวดหมู่ย่อย"
                :items="sub_category"
                item-value="_id"
                item-text="name"
                outlined
                rounded
                dense
                hide-details
                v-model="filter.sub_category_id"
              >
              </v-select> </v-col
          ></v-row>
          <v-row>
            <v-col cols="6" v-if="branch.split_bill_data.name" class="px-1">
              <v-select
                label="บิลบริษัท"
                :items="bill_select"
                item-value="value"
                item-text="text"
                outlined
                rounded
                dense
                hide-details
                v-model="filter.bill_select"
              >
              </v-select>
            </v-col>

            <v-col cols="6" class="px-1">
              <v-select
                label="ช่วงเวลา"
                v-model="timeRangeType"
                :items="timeRangeTypes"
                outlined
                rounded
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="timeRangeType == 1">
            <v-col cols="6 " class="px-0">
              <DatePicker
                label="วันที่"
                :value="filter.start_date"
                @onChange="(val) => (filter.start_date = val)"
                hideDetails
                outlined
                rounded
                dense
              />
            </v-col>
            <v-col cols="6" class="px-0">
              <DatePicker
                v-if="timeRangeType == 1"
                label="ถึงวันที่"
                :value="filter.end_date"
                @onChange="(val) => (filter.end_date = val)"
                hideDetails
                outlined
                rounded
                dense
            /></v-col>
          </v-row>
          <v-row v-if="timeRangeType == 2">
            <v-col cols="6" class="px-1">
              <v-menu
                ref="menu2"
                v-model="menu2"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="
                      filter.start_date ? getMonth(filter.start_date) : ''
                    "
                    label="เดือน"
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    hideDetails
                    outlined
                    rounded
                    dense
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  class="hidden-md-and-up"
                  v-model="filter.month"
                  type="month"
                  no-title
                  scrollable
                  locale="th"
                  @change="$refs.menu.save(filter.month), changeMonth()"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-3">
              <v-btn
                color="primary"
                small
                class="float-right"
                @click="getProductReportData()"
              >
                แสดงรายงาน <v-icon right>mdi-magnify</v-icon>
              </v-btn>
              <span class="float-right mt-1 mr-4">
                ยอดรวมทั้งหมด {{ total_sales | numeral('0,0.00') }} บาท</span
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-expansion-panels accordion class="mt-3">
        <v-expansion-panel v-for="(item, i) in list" :key="i">
          <v-expansion-panel-header
            @click="loadItem(item._id)"
            class="primary--text subtitle-2"
          >
            <v-row class="">
              <v-col cols="8">{{ item.name }}</v-col>
              <v-col cols="2">จำนวน {{ item.count }}</v-col>

              <v-col cols="2">{{ item.net_total | numeral('0,0.00') }}</v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              class="elevation-2 row-pointer text-body-2"
              :headers="headers"
              :items="product_list"
              :search="search"
              :loading="loading"
              :loading-text="$table_loading_text"
              :items-per-page="item.count"
              hide-default-footer
              item-key="id"
            >
              <template v-slot:[`item.date_of_issue`]="{ item }">
                {{ item.date_of_issue }}
              </template>
              <template v-slot:[`item.number`]="{ item }">
                {{ item.number }}
              </template>
              <template v-slot:[`item.customer_name`]="{ item }">
                {{ item.customer_name }}
              </template>
              <template v-slot:[`item.project_name`]="{ item }">
                {{ item.project_name }}
              </template>
              <template v-slot:[`item.product_count`]="{ item }">
                {{ item.product_count }}
              </template>
              <template v-slot:[`item.price`]="{ item }">
                {{ item.price | numeral('0,0.00') }}
              </template>
              <template v-slot:[`item.discount`]="{ item }">
                {{ item.discount | numeral('0,0.00') }}
              </template>
              <template v-slot:[`item.vat_price`]="{ item }">
                {{ item.vat_price | numeral('0,0.00') }}
              </template>
              <template v-slot:[`item.net_price`]="{ item }">
                {{ item.net_price | numeral('0,0.00') }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>

          <!-- <v-expansion-panel-content>
            <div v-for="dt in product_list" :key="dt.id">
              <v-row class="pr-5">
                <v-col cols="1" offset="1">{{ dt.date_of_issue }}</v-col>
                <v-col cols="1">{{ dt.number }}</v-col>
                <v-col cols="2">{{ dt.customer_name }}</v-col>
                <v-col cols="2">{{ dt.project_name }}</v-col>
                <v-col cols="1">{{ dt.product_count }}</v-col>
                <v-col cols="1">{{ dt.price | numeral('0,0.00') }}</v-col>
                <v-col cols="1">{{ dt.discount | numeral('0,0.00') }}</v-col>
                <v-col cols="1">{{ dt.vat_price | numeral('0,0.00') }}</v-col>
                <v-col cols="1">{{ dt.net_price | numeral('0,0.00') }}</v-col>
              </v-row>
            </div>
            <v-row v-if="product_remain != 0" class="pr-5">
              <v-col cols="11" offset="1" class="primary--text">
                <a @click="downloadItemExcel()">
                เหลืออีก {{ product_remain }} รายการ
                  ดาวน์โหลดสินค้านี้เป็น excel
                </a>
              </v-col>
            </v-row> 
          </v-expansion-panel-content>-->
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';

import * as moment from 'moment';

export default {
  components: {
    DatePicker,
  },

  data: () => ({
    total_sales: 0,
    filter: {
      start_date: moment().add(-0, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      month: '',
      category_id: '',
      sub_category_id: '',
      bill_select: 'all',
    },
    branch_id: '',
    branch: {
      name_for_receipt: '',
      split_bill_data: {
        name: '',
      },
    },
    product_id: '',
    menu: null,
    menu2: null,
    search: '',
    selected: [],
    loading: true,
    list: [],
    product_list: [],
    product_remain: 0,
    bill_select: [
      { value: 'all', text: 'ทั้งหมด' },
      { value: 'main', text: 'ชื่อออกใบเสร็จ 1' },
      { value: 'split', text: 'ชื่อออกใบเสร็จ 2' },
    ],
    category: [
      { value: '', text: 'ทั้งหมด' },
      { value: 'PC1', text: 'พรบ.' },
      { value: 'PC2', text: 'ประกันภัย' },
      { value: 'PC3', text: 'ขนส่ง' },
      { value: 'PC4', text: 'ทั่วไป' },
    ],
    sub_category: [],
    timeRangeType: 3,
    timeRangeTypes: [
      { value: 3, text: 'วันนี้' },
      { value: 0, text: 'เดือนปัจจุบัน' },
      { value: 2, text: 'เดือนก่อน' },
      { value: 1, text: 'เลือกช่วงวันที่' },
    ],
    headers: [
      { text: 'วันที่', value: 'date_of_issue', sortable: false },
      {
        text: 'เลขที่เอกสาร',
        value: 'number',
        filterable: false,
        sortable: false,
      },
      { text: 'ชื่อลูกค้า', value: 'customer_name', sortable: false },
      {
        text: 'ชื่อโปรเจ็ค',
        value: 'project_name',
        filterable: false,
        sortable: false,
      },
      {
        text: 'จำนวน',
        value: 'product_count',
        filterable: false,
        sortable: false,
      },
      {
        text: 'ราคาต่อหน่วย',
        value: 'price',
        filterable: false,
        sortable: false,
      },
      { text: 'ส่วนลด', value: 'discount', filterable: false, sortable: false },
      {
        text: 'ภาษีมูลค่าเพิ่ม',
        value: 'vat_price',
        filterable: false,
        sortable: false,
      },
      {
        text: 'ยอดรวมสุทธิ',
        value: 'net_price',
        filterable: false,
        sortable: false,
      },
    ],
  }),

  created() {
    this.branch_id = this.$store.state.selected_branch._id;

    this.getBranch();
    this.getSubCategory();
    this.getProductReportData();
  },

  methods: {
    getMonth(date) {
      return moment(date).format('YYYY-MM');
    },
    changeMonth() {
      this.filter.start_date = moment(this.filter.month, 'YYYY-MM')
        .startOf('month')
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss');
      this.filter.end_date = moment(this.filter.month, 'YYYY-MM')
        .endOf('month')
        .set({ hour: 23, minute: 59, second: 59 })
        .format('YYYY-MM-DDTHH:mm:ss');
    },
    async onCategoryChange() {
      this.filter.sub_category_id = '';
      this.getSubCategory();
    },
    async getSubCategory() {
      let filter = {
        branch_id: this.branch_id,
        category_id: this.filter.category_id,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/report/get_sub_category`, body)
        .then(async (res) => {
          this.sub_category = [].concat(
            [{ _id: '', name: 'ทั้งหมด' }],
            res.result
          );
          this.loading = false;
        });
    },
    async getBranch() {
      let body = {
        token: this.$jwt.sign({ branch_id: this.branch_id }, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/branch/get_by_id`, body)
        .then(async (res) => {
          this.branch = { ...res.result };
          this.bill_select = [
            { value: 'all', text: 'ทั้งหมด' },
            { value: 'main', text: this.branch.name_for_receipt },
            { value: 'split', text: this.branch.split_bill_data.name },
          ];
        })
        .catch((err) => {
          console.log('err', err);
        })
        .then(() => {
        });
    },
    async downloadOverallExcel() {
      this.loading = true;
      let filter = {
        branch_id: this.branch_id,
        category_id: this.filter.category_id,
        sub_category_id: this.filter.sub_category_id,
        bill_select: this.filter.bill_select,
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/report/download_report_product`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'ยอดขายสินค้า_' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'ยอดขายสินค้า_' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async downloadOverallCategoryExcel() {
      this.loading = true;
      let filter = {
        branch_id: this.branch_id,
        category_id: this.filter.category_id,
        sub_category_id: this.filter.sub_category_id,
        bill_select: this.filter.bill_select,
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/report/download_report_product_category`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'สรุปยอดขายสินค้า_' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'สรุปยอดขายสินค้า_' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async downloadOverallProductExcel() {
      this.loading = true;
      let filter = {
        branch_id: this.branch_id,
        category_id: this.filter.category_id,
        sub_category_id: this.filter.sub_category_id,
        bill_select: this.filter.bill_select,
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/report/download_report_product_summary`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'สรุปยอดขายสินค้า_' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'สรุปยอดขายสินค้า_' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async downloadItemExcel() {
      this.loading = true;
      let filter = {
        product_id: this.product_id,
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/report/download_report_product_item`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'ยอดขายสินค้า_' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'ยอดขายสินค้า_' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
    async getProductReportData() {
      this.list = [];
      this.total_sales = 0;
      let filter = {
        branch_id: this.branch_id,
        category_id: this.filter.category_id,
        sub_category_id: this.filter.sub_category_id,
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
        bill_select: this.filter.bill_select,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/report/report_by_product`, body)
        .then(async (res) => {
          this.list = res.result;
          for (let i = 0; i < this.list.length; i++) {
            this.total_sales += this.list[i].net_total;
          }
        });
    },
    async getProductReportDataItem() {
      let filter = {
        product_id: this.product_id,
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/report/report_by_product_item`, body)
        .then(async (res) => {
          this.product_list = res.result.data;
          this.product_remain = res.result.total_item;
          this.product_list.forEach((item, i) => {
            this.product_list[i].date_of_issue = this.formatDate(
              moment(item.date_of_issue, 'YYYY-MM-DDTHH:mm:ss')
            );
          });
        });
    },
    loadItem(product_id) {
      this.product_id = product_id;
      this.getProductReportDataItem();
    },
    formatDate(date) {
      if (!date) return '';
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
  },
  watch: {
    timeRangeType: function (value, oldValue) {
      if (value == 0) {
        // 0 = เดือนปัจจุบัน
        this.filter.start_date = moment()
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (value == 1) {
        // 1 = เลือกช่วงวันที่
        this.filter.start_date = moment()
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (value == 2) {
        // 2 = เดือนก่อน
        this.filter.start_date = moment()
          .subtract(1, 'months')
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .subtract(1, 'months')
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.month = moment().subtract(1, 'months').format('YYYY-MM');
      } else if (value == 3) {
        // 3 = วันนี้
        this.filter.start_date = moment()
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DDTHH:mm:ss');
      }
    },
  },
};
</script>
<style>
.report-headers {
  font-size: 14px;
  font-weight: 200;
  color: gray;
}
</style>
